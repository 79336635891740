:root {
	--toolbar-background-color: #003767;
	--control-button-fill-color: white;
	--left-drawer-background-color: white;
	--left-drawer-tab-color: rgba(0, 0, 0, 0.54);
	--left-drawer-tab-background-color: #46314F;

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: none;
	/*--peer-border: 1px solid rgba(255, 255, 255, 0.15);*/
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-bg-color: rgba(92, 65, 145, 0.9);
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);

	/* fonts */
	--font-family: 'Graphik', Helvetica, 'Arial Black', Arial, Sans-Serif;

	/* colors */
	--razzmatazz: #E71575;
	--violet-eggplant: #A21880;
	--white-smoke: #F8F8F8;
	--mineshaft: #4A4A4A;
	--gallery: #EAEAEA;
	--gray:  #808080;
	--gray-nickel: #BFBFBE;
	--bastille: #201723;
	--emporer: #525252;
	--guardsman-red: #C00;
	--amethyst-smoke: #A697AC;

	/* gradients */
	--razzmatazz-background-gradient: linear-gradient(to right, var(--razzmatazz), var(--violet-eggplant));
	--razzmatazz-background-gradient-inverted: linear-gradient(to left, var(--razzmatazz), var(--violet-eggplant));

	/* font weights */
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;

	/* font sizes */
	--font-size-xxxx-large: 4rem;    /* 64px */
	--font-size-xxx-large: 3rem;    /* 48px */
	--font-size-xx-large: 2.5rem;    /* 40px */
	--font-size-x-large: 2rem;       /* 32px */
	--font-size-larger: 1.6875rem;   /* 27px */
	--font-size-large: 1.5rem;       /* 24px */
	--font-size-medium-large: 1.25rem;/* 20px */
	--font-size-medium: 1rem;        /* 16px */
	--font-size-small: .875rem;      /* 14px */
	--font-size-smaller: .6875rem;   /* 11px */

	/* others */
	--card-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .15);
	--card-box-shadow-hover: 0 8px 16px 0 rgba(0, 0, 0, .25);
	--text-shadow: 2px 2px 3px rgba(0, 0, 0, .3);
	--title-shadow: 2px 2px 8px rgba(0, 0, 0, .3);
	--shadow-color: rgba(0,0,0, .05);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#withlocals-club
{
	height: 100%;
	width: 100%;
}